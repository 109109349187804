
// (secondary level pages and baseUrl if user comes from link)
export const DisplayPageTitles = {
    '/profile': {
        title: 'Profile',
        baseUrl: '/opportunities',
        isSideNavOpenOnBack: true
    },
    '/dashboard/my-investments/[investmentId]': {
        title: 'Investment Details',
        baseUrl: '/dashboard/my-investments'
    },
    '/opportunity/[id]': {
        title: `Opportunity Details`,
        baseUrl: '/opportunities'
    },
    '/ai-safe': {
        title: `aiSafe`,
        baseUrl: '/dashboard/ai-safe'
    },
    '/blogs/[slug]': {
        title: `Blog Details`,
        baseUrl: '/blogs'
    },
    '/dashboard/reinvestments/[reinvestmentLineId]': {
        title: 'Reinvestment Details',
        baseUrl: '/dashboard/reinvestments/[reinvestmentLineId]'
    },
    '/opportunity/[id]/reinvestment/[investmentId]/esign': {
        title: 'E-sign for Reinvestment',
        baseUrl: '/dashboard/reinvestments'
    },
};