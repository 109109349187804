import Text from '@atoms/Text';
import { Image } from '@atoms/index';

const AccordionListItem = ({ listItem }) => {
    const getAccordionContent = () => {
        if (listItem.name === 'Realized IRR') {
            return `${listItem.value}%`;
        }
        return listItem.icon !== 'mail-sign-hashtag-acc'
            ? `\u20B9 ${Math.floor(Number(listItem.value)).toLocaleString(
                'en-IN'
            )}`
            : Number(listItem.value);
    };
    return (
        <>
            {listItem.show && (
                <li
                    className={
                        'flex justify-between items-center mb-2 md:py-3 border-b-[1px] flex-grow  border-gray-100   last:border-b-0 last:mb-0 transition-all '
                    }
                >
                    <div className=" max-w-[75%] ">
                        <Text
                            content={listItem.name}
                            htmlTag="h1"
                            className="text-gray-500 font-medium text-xs mb-1 md:text-sm"
                        />

                        <Text
                            content={getAccordionContent()}
                            htmlTag="h1"
                            className="text-primary-500 font-semibold text-sm md:text-base"
                        />
                    </div>
                    <div className="bg-bgColor-50 w-9 h-9 md:w-12 md:h-12 rounded-full flex justify-center items-center">
                        <Image
                            className="text-purple-400 !w-5 !h-5 "
                            src={`./images/newDashboard/${listItem.icon}.svg`}
                            alt={listItem.icon}
                        />
                    </div>
                </li>
            )}
        </>
    );
};

export default AccordionListItem;
