const Tag = ({ label, borderRadius = 'rounded-full', ...property }) => {
    return (
        <button
            title={label}
            onClick={property.onClick}
            className={` ${borderRadius} ${property.className} text-${property.size} font-${property.weight}`}
            id="tagButton"
        >
            {' '}
            {label}{' '}
        </button>
    );
};
export default Tag;
