import React from 'react';

const Loader = ({ size = 'w-12 h-12' }) => {
    return (
        <div
            className={`${size} border-b-4 rounded-full border-primary-600 animate-spin`}
        ></div>
    );
};

export default Loader;
