import { Tag, Text } from '@atoms/index';

const Alert = ({ text, buttonLabel, onClick, className, buttonId }) => {
    return (
        <div
            className={`flex items-center gap-2 md:text-center text-basicBlack min-h-[40px] py-2 md:px-2 ${className}`}
        >
            <Text
                className="text-clip p5-medium"
                content={text}
            />
            <button className="p5-underline text-primary-600 whitespace-nowrap" id={buttonId} onClick={onClick}>
                {buttonLabel}
            </button>
        </div>
    );
};

export default Alert;
