import { Image } from '@atoms/index';

const IndependenceDayImages = ({
    width
}) => {
  return (
      <div className="relative max-w-screen-desktop-md mx-auto">
          {width >= 768 ? (
              <>
                  <Image
                      src="/images/opportunities/independence-desktop-flags.svg"
                      alt="id-flags"
                      className="absolute -top-5 -left-8 z-50 "
                  />
                  <Image
                      src="/images/opportunities/independence-rangoli.svg"
                      alt="id-rangoli"
                      className="absolute -top-[50px] -right-[58px] z-50 "
                  />
              </>
          ) : (
              <>
                  <Image
                      src="/images/opportunities/independence-mobile-left-element.svg"
                      alt="id-mobile-left"
                      className="absolute -top-9 -left-[100px] z-50 "
                  />
                  <Image
                      src="/images/opportunities/independence-mobile-right-element.svg"
                      alt="id-mobile-right"
                      className="absolute -top-9 -right-[100px] z-50 "
                  />
              </>
          )}
      </div>
  );
}

export default IndependenceDayImages;