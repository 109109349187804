import { Image, Text } from '@ui/atoms';

const ProgressBar = ({ totalProgress, promisedProgrees }) => {
    const FilledPercentage = () => (
        <div
            style={{
                minWidth: "96px",
                left: totalProgress > 70 ? `calc(${totalProgress > 100 ? 100 : totalProgress}% - 92px)` : `calc(${totalProgress}% - ${totalProgress ? '6px' : '0px'})`,
            }}
            className={`flex ${totalProgress > 70 ? 'flex-row-reverse justify-start' : `flex-row'}`} mt-2 absolute items-center gap-1`}
        >
            <Image src="/images/up-arrow.svg" className="w-3" />
            <Text
                content={`${totalProgress}% Filled`}
                className="text-center text-gray-700 p6-medium"
            />
        </div>
    );

    return (
        <div className="relative pt-2 pb-4">
            <div className="overflow-hidden text-xs flex rounded-lg bg-primary-30 h-2">
                <>
                    <div
                        style={{
                            width: `${totalProgress}%`,
                        }}
                        className={`justify-center text-center max-w-[100%] text-basicWhite shadow-none whitespace-nowrap !rounded-full bg-primary-400`}
                    ></div>

                    <div
                        style={{
                            width: `${
                                totalProgress ? totalProgress - promisedProgrees : promisedProgrees
                            }%`,
                        }}
                        className={`h-2 max-w-[100%] justify-center text-center absolute rounded-full text-basicWhite shadow-none whitespace-nowrap bg-secondary-500`}
                    ></div>
                </>
            </div>
            <FilledPercentage />
        </div>
    );
};

export default ProgressBar;
