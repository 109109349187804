// components/NavbarMobile/NavbarMobile.js
import React, { useEffect } from 'react';
import LazyImage from '@atoms/LazyImage';
import Avatar from '@atoms/Avatar';
import useNameAvatar from '@hooks/useNameAvatar';
import { Image, Link, Text } from '@ui/atoms';
import useDisableBodyOverflow from '@hooks/useDisableOverflow';
import NavigationPannel from './NavigationPannel';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { DisplayPageTitles } from '@helpers/displayPageTitles';
import { UserIcon } from '@heroicons/react/solid';
import useBackNavigation from '@hooks/useBackNavigation';
import { openMobileSideNavigation } from '@helpers/constants';
import 'animate.css/animate.min.css';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';

const WithAuthHeader = ({
    name,
    menuOpen,
    handleMenu,
    height,
    rmDetails,
    menu,
    isStaticPage,
    logout,
    kycStatus,
    isAppRedirection,
    appDashboardLink,
    notShowSideNavigation
}) => {
    const { avatar } = useNameAvatar(name);
    const router = useRouter();
    const pageProps = DisplayPageTitles[router?.pathname];
    useDisableBodyOverflow(menuOpen, [menuOpen]);
    const handleBack = useBackNavigation();

    useEffect(() => {
        // * (first and third level pages visiblity) && router.back behaviour on mobile 
        // * if user goes to any second level page from nav header and comes back then menu will be visible
        if(!pageProps && DisplayPageTitles?.[openMobileSideNavigation?.path]?.isSideNavOpenOnBack){
            handleMenu();
            openMobileSideNavigation['path'] = undefined;
        }
    }, [pageProps])

    return (
        <div className="flex items-center relative justify-between w-full xl:hidden">
            {!pageProps ? (
                <div className="flex items-center justify-between w-full px-5 md:px-8">
                    <div className={`flex items-center w-full`}>
                        <Link href="/">
                            <LazyImage
                                src={'/images/logo.svg'}
                                className="w-[94px] h-5 md:w-[112px] md:h-6 relative"
                                alt="logo"
                            />
                        </Link>
                    </div>

                    {isAppRedirection && (
                        <Link
                            href={appDashboardLink}
                            className="flex md:hidden h-5 w-[200px] gap-x-1 items-center justify-center mr-4"
                        >
                            <Image
                                src="/images/arrow-arc-left.svg"
                                className="w-4 h-4"
                            />
                            <Text
                                content="Back to App"
                                className="p5-underline text-primary-500"
                            />
                        </Link>
                    )}

                    {!notShowSideNavigation ? !menuOpen ? (
                        <div className="w-8 h-8" onClick={() => {
                            handleMenu();
                            triggerCustomEvent(gaEventNameEnum.CLICKED_INVESTOR_INITIAL_MOBILE_WEB_NAVIGATION)
                        }}>
                            <Avatar icon={avatar || <UserIcon className='w-5 h-5'/>} />
                        </div>
                    ) : (
                        <button
                            onClick={handleMenu}
                            className="outline-none mobile-menu-button"
                        >
                            <LazyImage
                                src={'/images/close-hamburger.svg'}
                                alt={'close'}
                                className="relative w-6 h-6"
                            />
                        </button>
                    ) : null}

                    <NavigationPannel
                        {...{
                            menuOpen,
                            height,
                            rmDetails,
                            avatar,
                            name,
                            isStaticPage,
                            logout,
                            handleMenu,
                            menu,
                            kycStatus,
                        }}
                    />
                </div>
            ) : (
                <div className="flex items-center w-full px-5 md:px-8">
                    <button className="w-5 h-5 absolute left-4" onClick={() =>  {
                            handleBack(pageProps?.baseUrl, () => {
                                openMobileSideNavigation['path'] = router.pathname;
                            })
                    }}>
                        <ArrowLeftIcon className="w-full h-full text-primary-500" />
                    </button>

                    {pageProps?.title ? (
                        <Text content={pageProps?.title} className='p3-medium text-gray-800 text-center w-full'/>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default WithAuthHeader;