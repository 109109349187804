import { otpLength } from '@ui/helpers/constants';
import Input from '../atoms/Input';

const OtpBox = ({ register }) => {
    return (
        <div className="text-center">
            <Input
                type="text"
                inputMode="numeric"
                id="otp"
                className="mt-10 w-1/2 md:w-[300px] xl:w-1/4 mx-auto bg-gray-100 text-center"
                register={register}
                placeholder="X - X - X - X - X - X"
                maxlength={otpLength}
                autoComplete="off"
            />
        </div>
    );
};

export default OtpBox;
