import LazyImage from '@atoms/LazyImage';
import { Avatar, Image, Link, Text } from '@atoms/index';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {
    getAuth,
    getIsAppRedirection,
    removeAppRedirection,
    removeAuth,
    removeReferralCode,
    removeReferralEntityType,
} from '@services/identity.service';
import { logoutUser } from '@services/users.service';
import useNameAvatar from 'hooks/useNameAvatar';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { reloadAuthenticatedTabs } from '@helpers/miscelleanous';
import useClient from 'hooks/useClient';
import HeaderMenuItems from '@molecules/menu/HeaderMenuItems';
import { Button } from '@ui/atoms/design-system';
import FestivalAnimation from './FestivalAnimation';
import { appDashboardLink, isFestivekandilVisible } from '@helpers/constants';
import {capitalizeWords, replaceKeysWithValues} from "@helpers/string";
import texts from "@components/Investor/en.json";
import NavHeaderMobile from './NavHeaderMobile';

const LoginButton = ({ onClick, className = '' }) => {
    const { width } = useWindowDimensions();
    return (
        <Button
            buttonType="primary"
            onClick={onClick}
            buttonText={`Login/Sign Up`}
            buttonSize={`${width < 768 ? 'extrasmall' : 'small'}`}
        />
    );
};

function NavHeader({
    menuClicked,
    hideHeaderOnScroll = false,
    translucent = false,
    homePage = false,
    menu,
    menuLoading,
    investorCache,
    festiveSeason,
}) {
    const router = useRouter();
    const auth = getAuth();
    const isAppRedirection = getIsAppRedirection();
    const showRMDetails = !!investorCache?.rmDetails;
    const rmDetails = investorCache?.rmDetails;
    const [menuOpen, setMenuOpen] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const lastScrollPosition = useRef(0);
    const [isLoggedIn, setIsLoggedIn] = useState(!!auth);
    const [profileClicked, setProfileClicked] = useState(false);
    const ref = useRef(null);
    const { width, height } = useWindowDimensions();
    const { avatar } = useNameAvatar(auth?.name);
    // creating a state for hydration issue: will remove this when we refactor this NavHeader
    const { isClient } = useClient();

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const onScroll = () => {
        const currentScrollPos = window.pageYOffset;

        /*
         * If the current scroll position is greater than the last scroll position
         * or If user scroll from top to down <= header-height
         * than only header visible
         */
        setIsHeaderVisible(
            lastScrollPosition.current > currentScrollPos || (width > 768 ? currentScrollPos <= 64 : currentScrollPos <= 48)
        );
        setOpenMenuIndex(null);
        setProfileClicked(false);

        lastScrollPosition.current = currentScrollPos;
    };

    useEffect(() => {
        setProfileClicked(false);
    }, [openMenuIndex]);

    useEffect(() => {
        if (hideHeaderOnScroll && !menuOpen) {
            window.addEventListener('scroll', onScroll);
            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
        if (menuOpen) {
            window.removeEventListener('scroll', onScroll);
        }
    }, [menuOpen]);

    const positionChatBotWithAuth = () => {
        const frameElement = document.getElementById('fc_frame');
        if (frameElement) {
            frameElement.classList.add('!bottom-[68px]');
        }
    }

    const positionChatBotWithOutAuth = () => {
        const frameElement = document.getElementById('fc_frame');
        if (frameElement && frameElement.classList.contains('!bottom-[68px]')) {
            frameElement.classList.remove('!bottom-[68px]');
        }
    }

    useEffect(() => {
        const auth = getAuth();
        if (auth) {
            setIsLoggedIn(true);
            positionChatBotWithAuth();
        } else {
            setIsLoggedIn(false);
            positionChatBotWithOutAuth();
        }
    }, [auth]);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setProfileClicked(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const destroySession = () => {
        removeReferralCode();
        removeReferralEntityType();
        removeAppRedirection();
        removeAuth();
        window.location.href = '/';
    };

    const logout = () => {
        handleMenu();
        const auth = getAuth();
        logoutUser(auth).then(destroySession).catch(destroySession);
        removeAppRedirection();
        setIsLoggedIn(false);
        // Broadcast the logout event to other tabs
        reloadAuthenticatedTabs();
    };

    const toTitleCase = (phrase) => {
        return phrase
            ? phrase
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            : '';
    };

    const isFestiveSeason = festiveSeason?.value === 'V1' && isFestivekandilVisible;

    return (
        <div
            id="sticky-header"
            className={`fixed ${
                !isHeaderVisible ? 'slide-up' : ''
            } flex flex-col items-center justify-center !m-0 h-12 md:h-16 navbar-sticky bg-basicWhite ${
                translucent ? 'bg-opacity-25' : ''
            } top-0 z-100 w-full`}
        >
            {/* Mobile and Tablet view */}
            {isClient ? (
                <NavHeaderMobile
                    name={toTitleCase(auth?.name)}
                    menuOpen={menuOpen}
                    handleMenu={handleMenu}
                    height={height}
                    rmDetails={investorCache?.rmDetails}
                    menu={menu}
                    isLoggedIn={isLoggedIn}
                    LoginButton={LoginButton}
                    menuClicked={menuClicked}
                    setMenuOpen={setMenuOpen}
                    isFestiveSeason={isFestiveSeason}
                    isHeaderVisible={isHeaderVisible}
                    isStaticPage={homePage}
                    kycStatus={investorCache?.kycStatus}
                    logout={logout}
                    isAppRedirection={isAppRedirection}
                    appDashboardLink={appDashboardLink}
                />
            ) : null}
            {/* desktop view */}
            <div
                className={`items-center relative justify-between hidden w-full h-full xl:flex max-w-[1440px] mx-auto px-4 md:px-6 ${!isLoggedIn && isClient && 'xl:px-18'
                }`}
            >
                <div className="flex items-center justify-start">
                    <div>
                        <Link href="/">
                            <LazyImage
                                src={'/images/logo.svg'}
                                className="w-[112px] h-6 ml-0 relative"
                                alt="logo"
                            />
                        </Link>
                    </div>
                </div>
                <div className="flex items-center justify-start h-full gap-6">
                    <HeaderMenuItems
                        menusLoading={menuLoading}
                        menu={menu}
                        isLoggedIn={isLoggedIn}
                        homePage={homePage}
                        openMenuIndex={openMenuIndex}
                        setOpenMenuIndex={setOpenMenuIndex}
                    />
                    {isLoggedIn && !menuLoading ? (
                        <>
                            <div className="flex items-center h-full" ref={ref}>
                                <nav className="relative flex items-center -ml-4">
                                    <Link
                                        href="#"
                                        className="relative flex-grow-0 p-1 text-base"
                                    >
                                        <div
                                            className="relative flex flex-grow-0 p-1 text-base xl:gap-2"
                                            onClick={() => {
                                                setProfileClicked(
                                                    !profileClicked
                                                );
                                            }}
                                        >
                                            {avatar ? (
                                                <Avatar
                                                    icon={avatar}
                                                    className="inline-block"
                                                />
                                            ) : (
                                                <div className="inline-block w-8 h-8">
                                                    <div className="w-8 h-8 p-2 rounded-full bg-primary-30">
                                                        <LazyImage
                                                            src="/images/UserProfile.svg"
                                                            className="relative w-full h-full"
                                                            alt="user"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex items-center text-gray-500">
                                                <span
                                                    className="self-center hidden p4-medium capitalize truncate align-middle xl:inline-block header-username font-heading"
                                                    title={auth?.name}
                                                >
                                                    {toTitleCase(auth?.name)}
                                                </span>
                                                <ChevronDownIcon
                                                    className={`h-5 w-5 transform-all duration-100 ${profileClicked &&
                                                        'rotate-180'
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    {profileClicked && (
                                        <div
                                            className={`absolute ${showRMDetails
                                                    ? 'w-[360px]'
                                                    : 'w-[183px]'
                                            }   shadow-xl text-center bg-basicWhite border border-gray-300 rounded-md right-0 top-10`}
                                        >
                                            <div className="m-4">
                                                <div
                                                    className={`flex ${showRMDetails
                                                            ? 'flex-row h-12'
                                                            : 'flex-col'
                                                    } justify-around items-center  text-primary-500 `}
                                                >
                                                    <button
                                                        className={`hover:bg-gray-200  ${showRMDetails
                                                                ? 'flex-1 h-full'
                                                                : 'w-full h-12'
                                                        } bg-gray-100 rounded-md font-semibold text-base`}
                                                        onClick={async () => {
                                                            await router.push(
                                                                '/profile'
                                                            );
                                                            setProfileClicked(
                                                                false
                                                            );
                                                        }}
                                                        id="myProileButton"
                                                    >
                                                        <Image
                                                            className={`inline h-5 ${showRMDetails
                                                                    ? 'pr-4'
                                                                    : 'pr-3'
                                                            }`}
                                                            src={`/images/UserProfile.svg`}
                                                        />
                                                        Profile
                                                    </button>
                                                    <div
                                                        className={`${showRMDetails
                                                                ? 'h-4 w-0.5 mx-3 bg-gray-300'
                                                                : 'h-0.5 w-full my-4 bg-gray-100'
                                                        } `}
                                                    />
                                                    <button
                                                        className={`hover:bg-gray-200  ${showRMDetails
                                                                ? 'flex-1 h-full'
                                                                : 'w-full h-12'
                                                        } rounded-md font-semibold text-base`}
                                                        onClick={logout}
                                                        id="gtm-logout-button"
                                                    >
                                                        <Image
                                                            className={`inline h-5 ${showRMDetails
                                                                    ? 'pr-4'
                                                                    : 'pr-3'
                                                            }`}
                                                            src={`/images/Logout.svg`}
                                                        />
                                                        Logout
                                                    </button>
                                                </div>
                                                {showRMDetails && (
                                                    <>
                                                        <div className="h-0.5 mt-4 w-full bg-gray-100" />
                                                        <Text
                                                            className={`py-4 text-gray-400 font-medium text-sm text-left`}
                                                            content="Your Relationship Manager"
                                                        />
                                                        <div className="flex items-center justify-start">
                                                            <Image
                                                                src={`${rmDetails?.image
                                                                        ? rmDetails?.image
                                                                        : '/images/user-solid.svg'
                                                                }`}
                                                                className={`w-12 h-12  ${!rmDetails?.image &&
                                                                    'border-2 border-gray-400 bg-gray-400'
                                                                } rounded-full`}
                                                            />
                                                            <Text
                                                                className={`pl-4 text-gray-900 font-semibold text-xl`}
                                                                content={
                                                                    rmDetails?.name
                                                                }
                                                            />
                                                        </div>
                                                        {rmDetails?.email ? (
                                                            <div className="mt-4 text-left ">
                                                                <Text
                                                                    className={`pr-6 text-gray-900 font-semibold text-base inline`}
                                                                    content="Email"
                                                                />
                                                                <a
                                                                    href={`mailto:${rmDetails?.email}`}
                                                                >
                                                                    <Text
                                                                        className={`hover:text-primary-500 hover:font-medium hover:-ml-px text-gray-900 text-base inline`}
                                                                        content={
                                                                            rmDetails?.email
                                                                        }
                                                                    />
                                                                </a>
                                                            </div>
                                                        ) : null}

                                                        {rmDetails?.mobile ? (
                                                            <>
                                                                <div className="my-2 text-left ">
                                                                    <Text
                                                                        className={`text-gray-900 font-semibold text-base inline`}
                                                                        content="Phone"
                                                                    />
                                                                    <Text
                                                                        className={`pl-4 text-gray-900 text-base inline`}
                                                                        content={
                                                                            rmDetails?.mobile
                                                                        }
                                                                    />
                                                                </div>
                                                                <a
                                                                    href={`https://wa.me/${
                                                                        rmDetails?.mobile
                                                                    }?text=${encodeURIComponent(
                                                                        replaceKeysWithValues(
                                                                            texts?.RMWhatsappMessage,
                                                                            {
                                                                                '{name}':
                                                                                    capitalizeWords(
                                                                                        auth?.name
                                                                                    ),
                                                                                '{mobile}':
                                                                                    auth?.mobile,
                                                                            }
                                                                        )
                                                                    )}`}
                                                                    target="_blank"
                                                                    className="w-full"
                                                                >
                                                                    <Button
                                                                        id="chatWithRM"
                                                                        buttonSize="small"
                                                                        widthClass={`w-full`}
                                                                        prefixComponent={
                                                                            <Image
                                                                                src={`/images/Chat.svg`}
                                                                            />
                                                                        }
                                                                        buttonText={`Chat With ${
                                                                            rmDetails?.name.split(
                                                                                ' '
                                                                            )[0]
                                                                        }`}
                                                                    />
                                                                </a>
                                                            </>
                                                        ) : null}
                                                        {rmDetails?.mobile ? (
                                                            <div className="flex justify-start mt-5 text-left ">
                                                                <Image
                                                                    className="-mt-5"
                                                                    src={`/images/InfoDark.svg`}
                                                                />
                                                                <Text
                                                                    className={`pl-2.5 text-gray-500 text-xs`}
                                                                    content={`Your relationship manager will be available on Whatsapp chat between 10:30 AM-7:30 PM, Monday to Friday.`}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </nav>
                            </div>
                        </>
                    ) : !menuLoading ? (
                        <>
                            <LoginButton
                                onClick={() => {
                                    menuClicked('Login');
                                }}
                            />
                        </>
                    ) : null}
                </div>

                {isFestiveSeason && isHeaderVisible ? (
                    <FestivalAnimation isLoggedIn={isLoggedIn} />
                ) : null}
            </div>
        </div>
    );
}

export default NavHeader;
