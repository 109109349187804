import LazyImage from '@atoms/LazyImage';
import FestivalAnimation from '@molecules/FestivalAnimation';
import HamburgerMenuItem from '@molecules/menu/HamburgerMenuItem';
import { Link } from '@ui/atoms';
import React from 'react'

const WithoutAuthHeader = ({
    menu,
    menuOpen,
    LoginButton,
    menuClicked,
    handleMenu,
    setMenuOpen,
    isFestiveSeason,
    isHeaderVisible,
    height
}) => {
  return (
      <div className="flex items-center relative justify-between w-full xl:hidden">
          <div className="flex items-center justify-between w-full px-5 md:px-8">
              <div className="flex items-center justify-between w-full">
                  <Link href="/">
                      <LazyImage
                          src={'/images/logo.svg'}
                          className="w-[94px] h-5 md:w-[112px] md:h-6 relative"
                          alt="logo"
                      />
                  </Link>
              </div>
              <div className="flex items-center gap-x-4 md:gap-x-6">
                  {!menuOpen ? (
                      <div className="flex items-center md:hidden gap-x-2">
                          <LoginButton
                              onClick={() => {
                                  menuClicked('Login');
                              }}
                          />
                      </div>
                  ) : null}

                  <div className="items-center hidden md:flex gap-x-2">
                      <LoginButton
                          onClick={() => {
                              menuClicked('Login');
                          }}
                      />
                  </div>
                   <button
                          onClick={handleMenu}
                          className="outline-none mobile-menu-button"
                      >
                          <LazyImage
                              src={
                                  !menuOpen
                                      ? '/images/homepage/bar.svg'
                                      : '/images/close-hamburger.svg'
                              }
                              alt={!menuOpen ? 'bar' : 'close'}
                              className="relative w-6 h-6"
                          />
                    </button>
              </div>
              <div
                  className={`${
                      menuOpen
                          ? 'mobile-menu-visible block'
                          : 'mobile-menu-hidden hidden'
                  } fixed top-12 md:top-16 right-0 z-50 mobile-menu min-width-full md:w-[350px]`}
              >
                  <div
                      className="relative flex flex-col justify-between w-screen pb-20 bg-basicWhite md:w-full backdrop-blur-lg mobile-menu-content"
                      style={{ height: height }}
                  >
                      <ul className="px-5 overflow-y-auto">
                          {menu?.filter((each) => each !== null && each?.showWhenNotLoggedIn).map((item) => {
                                  {
                                      return (
                                          <li key={item.name}>
                                              <HamburgerMenuItem
                                                  menuItem={item}
                                                  closeMenu={() =>
                                                      setMenuOpen(false)
                                                  }
                                                  isLoggedIn={false}
                                              />
                                          </li>
                                      );
                                  }
                              })}
                      </ul>
                  </div>
              </div>
          </div>

          {isFestiveSeason && isHeaderVisible ? (
              <FestivalAnimation isLoggedIn={false} />
          ) : null}
      </div>
  );
}

export default WithoutAuthHeader;