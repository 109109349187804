import { Text } from '.';

const UploadDrag = ({ label, onclick, ...property }) => {
    return (
        <>
            {label && <label className="mt-10">{label}</label>}
            <div className="p-5 mt-2 text-center border border-dotted rounded-lg border-gray-400">
                <Text
                    content={
                        <p>
                            Drop your file(s) here or{' '}
                            <span className="text-yellow-500">browse</span>
                        </p>
                    }
                />
                <Text
                    content="Max File Size: 20MB"
                    className="p-1 text-sm text-gray-400"
                />
                <input
                    className="cursor-pointer absolute block opacity-0 pin-r pin-t top-5"
                    type="file"
                    name={property.name}
                    id={property.id}
                    onClick={onclick}
                    multiple
                />
            </div>
        </>
    );
};

export default UploadDrag;
