import Image from '@atoms/Image';
import { opportunitiesViews } from '@helpers/constants';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import React, { useMemo } from 'react';

const ViewFilter = ({ view = opportunitiesViews.CARD, setView = () => null }) => {
    const renderFilters = useMemo(() => (
        <div className="flex justify-center items-center">
            <div
                className={`px-[10px] py-2 cursor-pointer border border-gray-100 ${view === opportunitiesViews.CARD ? 'bg-basicWhite' : 'bg-gray-100'}`}
                onClick={() => setView(opportunitiesViews.CARD)}
            >
                {view === opportunitiesViews.CARD ? (
                    <Image
                        src={`/images/opportunities/primary-squares.svg`}
                        className="w-4 h-4"
                    />
                ) : (
                    <Image
                        src={'/images/opportunities/secondary-squares.svg'}
                        className="w-4 h-4"
                    />
                )}
            </div>
            <div
                className={`px-[10px] py-2 cursor-pointer border border-gray-100 ${view === opportunitiesViews.LIST ? 'bg-basicWhite' : 'bg-gray-100'}`}
                onClick={() => {
                    triggerCustomEvent(gaEventNameEnum.CLICKED_LIST_VIEW_OPPORTUNITIES_MOBILE);
                    setView(opportunitiesViews.LIST)
                }}
            >
                {view === opportunitiesViews.CARD ? (
                    <Image
                        src={`/images/opportunities/secondary-tiles.svg`}
                        className="w-4 h-4"
                    />
                ) : (
                    <Image
                        src={'/images/opportunities/primary-tiles.svg'}
                        className="w-4 h-4"
                    />
                )}
            </div>
        </div>
    ), [view]);

    return renderFilters;
};

export default ViewFilter;
