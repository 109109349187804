import Text from '@atoms/Text';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Image } from '@ui/atoms';
import { useRouter } from 'next/router';

const ExpandNavigationItem = ({
    field,
    icon,
    children,
    className = "flex items-center flex-col p-2 rounded-xl",
    showChildren = false,
    link = '',
    setShowChildren,
    handleMenu,
    gTag
}) => {
  const router = useRouter();
  const show = showChildren === field?.name;

  const handleClick = (link) => {
        if(link){
            router.push(link);
            if(gTag) triggerCustomEvent(gTag);
            if(handleMenu) handleMenu(); // toggle the menu
        }else if(show){
            setShowChildren('')
        }else{
            if(gTag) triggerCustomEvent(gTag);
            setShowChildren(field?.name)
        }
  }
    
  return (
      <div
          className={`${className} ${show ? 'bg-gray-50' : 'bg-basicWhite'}`}
      >
          <div className="flex items-center justify-between w-full cursor-pointer" onClick={() => handleClick(link)}>
              <div className="flex items-center w-full gap-3">
                  {icon ? (
                     <div className={`p-2 rounded-lg ${show ? 'bg-basicWhite' : 'bg-gray-50'}`}>
                         <Image src={icon} alt={field?.name} className='w-6 h-6'/>
                     </div>
                  ) : null}

                  {field?.name ? (
                      <Text content={field?.name} className={field?.class} />
                  ) : null}
              </div>

              {children ? (
                  <button>
                      {!(show) ? (
                          <ChevronDownIcon className="w-4 h-4" />
                      ) : (
                          <ChevronUpIcon className="w-4 h-4" />
                      )}
                  </button>
              ) : null}
          </div>
          {show && children ? children : null}
      </div>
  );
}

export default ExpandNavigationItem;
