import { Text } from '@atoms/index';
import texts from '@molecules/Footer/V2/en.json';
import { replaceKeysWithValues } from '@helpers/string';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { brandName } from '@helpers/constants';

const FooterText = () => {
    const [showText, setShowText] = useState(false);
    const ease = [0.43, 0.13, 0.23, 0.96];
    return (
        <>
            <div className="w-full h-[0.5px] bg-primary-30 my-4 xl:my-6" />
            <div className="pb-4 md:pb-8">
                <Text
                    content={texts?.footerFirstDisplayText}
                    className={`p6-regular md:p5-regular text-primary-50 md:inline`}
                />

                {showText && (
                    <motion.div
                        initial={{ y: '-100%', display: 'none' }}
                        animate={{ y: '0%', display: 'block' }}
                        transition={{ ease, duration: 0.3 }}
                        className="p6-regular md:p5-regular text-primary-50"
                    >
                        <Text
                            content={replaceKeysWithValues(
                                texts?.footerReadMoreInitialText,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                            className="my-4"
                        />
                        <Text
                            content={texts?.WhatIsSizeOfAlternativeInvestment}
                            className={`p4-semibold md:p3-semibold pb-1 text-primary-50`}
                        />
                        <Text content={texts?.SizeOfAlternativeInvestmentAns} />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.HowaltGraafEnsureHighQualityInvestment,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                            className={`p4-semibold md:p3-semibold pb-1 text-primary-50 mt-4`}
                        />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.altGraafEnsureHighQualityInvestmentAns,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                        />
                        <Text
                            content={texts?.HighQualityInvestmentPointOne}
                            className="pl-4"
                        />
                        <Text
                            content={texts?.HighQualityInvestmentPointTwo}
                            className="pl-4"
                        />
                        <Text
                            content={texts?.HighQualityInvestmentPointThree}
                            className="pl-4"
                        />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.WhatInvestmentProductsAreAvailable,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                            className={`p4-semibold md:p3-semibold pb-1 text-primary-50 mt-4`}
                        />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.InvestmentProductsAvailableAns,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                        />
                        <Text
                            content={texts?.InvoiceDiscounting}
                            className={`p5-semibold md:p4-semibold mt-4`}
                        />
                        {texts?.InvoiceDiscountingDesc}
                        <Text
                            content={texts?.CorporateDebt}
                            className={`p5-semibold md:p4-semibold mt-4`}
                        />
                        {texts?.CorporateDebtDesc}
                        <Text
                            content={texts?.AssetLeasing}
                            className={`p5-semibold md:p4-semibold mt-4`}
                        />
                        {texts?.AssetLeasingDesc}
                        <Text
                            content={texts?.VentureDebt}
                            className={`p5-semibold md:p4-semibold mt-4`}
                        />
                        {texts?.VentureDebtDesc}
                        <Text
                            content={replaceKeysWithValues(texts?.Whyaltgraaf, {
                                '{brandName}': brandName,
                            })}
                            className={`p4-semibold md:p3-semibold pb-1 mt-4`}
                        />
                        <Text content={texts?.WhyaltgraafPointOne} />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.WhyaltgraafPointTwo,
                                {
                                    '{brandName}': brandName,
                                }
                            )}
                        />
                        <Text content={texts?.WhyaltgraafPointThree} />
                        <Text content={texts?.WhyaltgraafPointFour} />
                        <Text content={texts?.WhyaltgraafPointFive} />
                    </motion.div>
                )}

                <div
                    onClick={() => setShowText(!showText)}
                    className={`p6-underline md:p5-underline text-primary-50 cursor-pointer ${showText ? 'pt-2' : 'md:inline md:pl-1 pt-1 md:pt-0'
                        }`}
                >
                    {!showText ? texts?.ReadMore : texts?.ReadLess}
                </div>
            </div>{' '}
        </>
    );
};

export default FooterText;
