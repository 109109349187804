import { UPLOAD_FILE_URL } from '@services/urls/files';
import * as CsrHttpService from '@http/csr-http.service';
import { hasWindow } from '@services/http.service';
import * as SsrHttpService from '@http/ssr-http.service';

export const fileUploadHandler = (bucket, file, key, ctx = null) => {
    const url = UPLOAD_FILE_URL();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', key);
    formData.append('bucket', bucket);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, formData);
    }
    return SsrHttpService.postWithAuth(url, formData, ctx);
};
