import Text from '@atoms/Text';

const KycError = ({ error }) => {
    return (
        <div className="mt-4 px-3 py-2 w-full bg-skin-50 border-[0.5px] border-skin-200 rounded-lg">
            <Text
                content={error}
                className="text-semantic-error-base p6-medium"
                isInnerHtml
            />
        </div>
    );
};

export default KycError;
