import { Image, Text } from '@atoms/index';
import { FilterIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { OpportunityOverviewHeaderText } from './OpportunityOverviewHeaderText';
import Skeleton from '@atoms/Skeleton';
import { Button } from '@ui/atoms/design-system';
import { closedFilterKeyValuesMap, opportunitiesViews, investmentFallbackStats } from '@helpers/constants';
import AppliedFilters from './AppliedFilters';
import texts from '@components/Investor/Opportunities/en.json';
import { replaceKeysWithValues } from '@helpers/string';
import PoperBurst from '/public/assets/santaclaus.json';
import { useLottie } from 'lottie-react';
import { useRef } from 'react';
import useIntersection from 'hooks/useIntersection';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ViewFilter from './ViewFilter';
import IndependenceDayImages from "@components/Investor/Opportunities/IndependenceDayImages"

const OpportunityOverview = ({ activeButton, filter, isChristmasSession, view = opportunitiesViews.CARD, setView, ...property }) => {
    const {
        stats,
        closedDealsResults,
        isFilterModalVisible,
        setIsFilterModalVisible,
        onBackFromResults,
        closeDealResultParams,
        RecentlyClosedOpportunityFilters,
        onResetClosedFilters,
        onRemoveCloseDealFilter,
        showIndependenceDayImages,
    } = property;

    const BannerStats = [
        {
            title: texts?.CompaniesOnboarded,
            text: `${stats?.companies || investmentFallbackStats.companiesOnboarded}`,
        },
        {
            title: texts?.TotalDealsOriginated,
            text: `${stats?.originatedDeals || investmentFallbackStats.totalDealsOriginated}`,
        },
        {
            title: texts?.TotalDealsRepaid,
            text: `${stats?.repaidDeals || investmentFallbackStats.totalDealsRepaid}`,
        },
        {
            title: texts?.TotalAmountInvested,
            text: `${stats?.investedAmount || investmentFallbackStats.investedAmount}+ Cr`,
        },
        {
            title: texts?.TotalRepaymentsDelivered,
            text: `${stats?.repaidAmount || investmentFallbackStats.repaidAmount}+ Cr`,
        },
    ];

    const options = {
        animationData: PoperBurst,
        loop: true,
    };
    const { View } = useLottie(options);

    const christmasAnimationRef = useRef();
    const isChristmasAnimationVisible = useIntersection(
        christmasAnimationRef,
        '-125px'
    );

    const { width } = useWindowDimensions();

    const FilterByButton = () => (
        <Button
            buttonType="outline"
            buttonText={texts?.FilterBy}
            prefixComponent={<FilterIcon className="w-4 h-4 mr-1" />}
            className={`p5-medium md:bg-primary-10`}
            onClick={() => {
                setIsFilterModalVisible(!isFilterModalVisible)
            }}
            buttonSize="extraSmall"
        />
    );

    return (
        <>
            {!closedDealsResults?.visible && showIndependenceDayImages ? <IndependenceDayImages width={width} /> : null}
            <div
                className={`${
                    closedDealsResults?.visible
                        ? ''
                        : `xl:sticky z-40 items-center w-full py-5 mb-6 bg-basicWhite border-b border-gray-200 -top-8`
                }`}
                ref={christmasAnimationRef}
            >
                <div className="relative max-w-screen-desktop-md mx-auto w-full">
                    <div
                        className={`${
                            isChristmasAnimationVisible &&
                            !closedDealsResults?.visible &&
                            isChristmasSession
                                ? 'absolute'
                                : 'hidden'
                        } top-10 sm:top-6 -right-0 md:-top-1 md:right-0  w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] md:w-[150px] md:h-[150px] z-0`}
                    >
                        {View}
                    </div>
                    <div className="font-paragraph maxScreen:mx-auto max-w-screen-desktop-md xl:mx-auto">
                        {/* Top banner view */}
                        <div
                            className={`grid xl:space-x-0 xl:grid md:grid-cols-3 xl:grid-cols-9 mx-6`}
                        >
                            {/* Recent close deals  */}
                            {!closedDealsResults?.visible ? (
                                <div className="flex flex-col justify-between col-span-12 xl:col-span-2 md:flex-row xl:flex-col">
                                    <div className="w-full mx-auto mb-3">
                                        <div className="flex flex-col">
                                            <div className="z-10 flex-1">
                                                <div className="flex flex-col items-center pb-4 px-4 pt-1 md:p-4 md:px-0 xl:items-start">
                                                    <Text
                                                        content={
                                                            texts?.Opportunities
                                                        }
                                                        className="h5-semibold md:h4-semibold text-center capitalize text-primary-500 md:text-left"
                                                    />
                                                    <Text
                                                        content={
                                                            texts?.ContinueWithYourInvestingJourney
                                                        }
                                                        className="pt-2 mx-0 p5-regular text-center text-gray-500 md:text-left"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Stats */}
                            <div className="flex-wrap items-center justify-center maxScreen:justify-start pl-10 maxScreen:gap-14 xl:gap-10 hidden col-span-2 xl:flex xl:flex-[3] xl:col-span-7">
                                {stats ? (
                                    !closedDealsResults?.visible &&
                                    stats &&
                                    BannerStats?.map((stat, index) => (
                                        <OpportunityOverviewHeaderText
                                            key={index}
                                            title={stat.title}
                                            text={stat.text}
                                        />
                                    ))
                                ) : (
                                    <Skeleton
                                        render={
                                            <>
                                                <div className="w-140 bg-gray-100 h-4 rounded-md "></div>
                                                <div className="w-120 bg-gray-100 h-4 rounded-md "></div>
                                                <div className="w-96 bg-gray-100 h-4 rounded-md "></div>
                                            </>
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        <div
                            className={`mx-auto col-span-full w-full transform ${
                                closedDealsResults?.visible ? 'hidden' : 'block'
                            }`}
                        >
                            <div
                                className={`md:absolute bottom-2 left-0 flex flex-col items-center justify-center w-full gap-4 md:translate-y-full bg-no-repeat bg-cover rounded-xl md:px-5 xl:flex-row md:justify-between ${
                                    filter !== 'open' &&
                                    width < 1280 &&
                                    'md:bg-closed-opportunity-sticky-actions-sm'
                                }`}
                            >
                                <ul className="flex items-center self-center p-1 mx-auto bg-primary-10 border border-gray-200 rounded-xl xl:mx-0">
                                    <li>
                                        <Button
                                            id="activeOpportunitiesButton"
                                            buttonType={
                                                filter === 'open'
                                                    ? 'primary'
                                                    : 'tertiary'
                                            }
                                            buttonText={texts?.Active}
                                            buttonSize="extraSmall"
                                            onClick={() => activeButton('open')}
                                        />
                                    </li>

                                    <li>
                                        <Button
                                            id="gtm-click-closed-opportunity"
                                            buttonType={
                                                filter !== 'open'
                                                    ? 'primary'
                                                    : 'tertiary'
                                            }
                                            buttonSize="extraSmall"
                                            buttonText={replaceKeysWithValues(
                                                texts?.RecentlyClosed,
                                                {
                                                    '{count}':
                                                        stats?.recentlyClosedDeals ||
                                                        '-',
                                                }
                                            )}
                                            onClick={() =>
                                                activeButton('closed')
                                            }
                                        />
                                    </li>
                                </ul>

                                {filter === 'closed' ? (
                                    <FilterByButton />
                                ) : (
                                    <div className="md:hidden mt-2">
                                        <ViewFilter
                                            view={view}
                                            setView={setView}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ** Applied filters visible on result screen ** (closed opportunities) */}
            <div
                className={`flex flex-col gap-6 mx-auto xl:px-0 bg-basicWhite ${
                    closedDealsResults?.visible ? 'flex' : 'hidden'
                }`}
            >
                <div className="max-w-screen-desktop-md mx-auto w-full">
                    <div
                        className={`px-2 mt-5 mb-3 col-span-full flex gap-1 items-center cursor-pointer ${
                            closedDealsResults?.visible ? 'block' : 'hidden'
                        }`}
                        onClick={onBackFromResults}
                    >
                        <ChevronLeftIcon className="w-6 h-6 text-gray-600" />
                        <Text
                            content={texts?.BackToRecentlyClosedDeals}
                            className="p5-medium text-center text-gray-600 font-paragraph md:text-left"
                        />
                    </div>
                    <AppliedFilters
                        params={closeDealResultParams}
                        options={RecentlyClosedOpportunityFilters}
                        // ** labelToShow is a key value pair for displaying value if we want to show different value of any key **
                        labelToShow={{
                            replaceValuesWithLabel: closedFilterKeyValuesMap,
                            replaceItems: ['state', 'type', 'riskCategory'],
                        }}
                        openFilters={() => setIsFilterModalVisible(true)}
                        onRemove={onRemoveCloseDealFilter}
                        count={closedDealsResults?.data?.count}
                        onReset={onResetClosedFilters}
                    />
                </div>
            </div>
        </>
    );
};

export default OpportunityOverview;
