import { Text, Image } from '@atoms/index';

const Loader = ({ content, imageUrl }) => {
    return (
        <>
            {imageUrl ? <Image src={imageUrl} className="mr-3" /> : null}
            <Text content={content} className='p6-regular pt-2' />
        </>
    );
};

export default Loader;
