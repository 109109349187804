import moment from "moment";
import { useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/solid';
import FooterLinks from "./FooterLinks";
import AppLinks from "./AppLinks";
import texts from '@molecules/Footer/V2/en.json';
import { useRouter } from 'next/router';
import Text from "@atoms/Text";
import Image from "@atoms/Image";
import { replaceKeysWithValues } from "@helpers/string";
import { brandName } from "@helpers/constants";
import { footerData, socialMediaLinks } from "./helper";
import { Link } from "@atoms/index";
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import FooterText from "./FooterText";
import { triggerMenuGaEvents } from "@helpers/miscelleanous";

const LoggedOutFooter = () => {
    const [expanded, setExpanded] = useState(false);
    const copyRightYear = moment().format('YYYY');
    const router = useRouter();
    const routerPath = router.pathname;
    const { width } = useWindowDimensions();

    return (
        <div className='bg-primary-600 z-40'  id="app-download">
            <div
                className='max-w-screen-maxScreen mx-auto p-4 md:p-6 xl:pt-10 xl:px-18'
            >
                <div
                    className='p-3 md:py-3 md:px-6 mb-6 md:mb-10 w-full bg-basicBlack/25 rounded-xl md:rounded-2xl bg-footer-app border-1 border-primary-10/10 flex flex-row justify-between items-center gap-x-6 gap-y-4'
                >
                    <Text className="text-basicWhite p5-regular md:h5-regular text-left" content={
                        <>{texts?.InvestmentsMadeEasyWithApp}<span className='p5-semibold md:h5-semibold'>{texts?.AltGraafApp}</span></>
                    }></Text>
                    <AppLinks className="shrink-0" />
                </div>
                <div
                    className='flex flex-col xl:flex-row justify-between gap-y-6 md:gap-y-10'
                >
                    <div
                        className={`flex  ${routerPath === '/signup'
                            ? 'flex-row justify-between gap-y-5 w-full'
                            : 'flex-col md:flex-row xl:flex-col xl:w-[396px] gap-5 md:gap-12 xl:gap-10'
                            }`}
                    >
                        <div className="flex-col flex gap-2 md:w-[87%] xl:w-full">
                            <div
                                className={`flex xl:justify-between md:justify-start justify-between md:gap-6 xl:gap-0 ${routerPath !== '/signup' &&
                                    'flex-1 xl:flex-none'
                                    }`}
                            >
                                <Image
                                    src={'/images/logo-white.svg'}
                                    alt={replaceKeysWithValues(
                                        texts?.LogoAlt,
                                        {
                                            '{brandName}': brandName,
                                        }
                                    )}
                                    className={`w-[112px] h-6`}
                                />

                                {routerPath !== '/signup' && (
                                    <div
                                        className={`flex space-x-3`}
                                    >
                                        {socialMediaLinks.map(
                                            (socialMedia, index) => (
                                                <Link
                                                    key={index}
                                                    href={socialMedia.url}
                                                    target="_blank"
                                                    children={
                                                        <Image
                                                            className="w-6 h-6"
                                                            src={
                                                                socialMedia.image
                                                            }
                                                            alt={replaceKeysWithValues(
                                                                texts?.SocialMediaLogoAlt,
                                                                {
                                                                    '{socialMedia}':
                                                                        socialMedia.name,
                                                                    '{brandName}':
                                                                        brandName,
                                                                }
                                                            )}
                                                        />
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`flex gap-2 ${routerPath !== '/signup' &&
                                    'flex-1 xl:flex-none'
                                    }`}
                            >
                                <Image
                                    className="w-4 h-5 mt-[2px]"
                                    src="/images/location-primary-50.svg"
                                    alt={texts?.location}
                                />
                                <Text
                                    className="p5-regular text-primary-30"
                                    content={replaceKeysWithValues(
                                        texts?.Address,
                                        {
                                            '{brandName}': brandName,
                                        }
                                    )}
                                />
                            </div>
                        </div>
                            <div
                                className={`flex gap-2 ${routerPath !== '/signup' &&
                                    'flex-none'} hidden md:block`}
                            >
                                <Image
                                    className="w-20 h-20"
                                    src="/images/iso-certificate.webp"
                                    alt={texts?.iso}
                                />
                            </div>
                    </div>

                    {routerPath !== '/signup' && (
                        <div
                            className={`flex flex-col  md:flex-row md:justify-between md:gap-14`}
                        >
                            {footerData
                                .filter((item) => item !== null)
                                .map((menu, index) => {
                                    return (
                                        <div
                                            className={`flex flex-col md:gap-2.5  ${width < 768 &&
                                                'border-b border-primary-100 last:border-none'
                                                } ${index === expanded &&
                                                'pb-4'
                                                }`}
                                            key={`${menu?.title}-${index}`}
                                        >
                                            <motion.header
                                                initial={
                                                    width >= 768
                                                        ? true
                                                        : false
                                                }
                                                animate={{}}
                                                onClick={() =>
                                                    width < 768 &&
                                                    setExpanded(
                                                        index ===
                                                            expanded
                                                            ? false
                                                            : index
                                                    )
                                                }
                                                className={`flex items-center justify-between p5-medium md:p4-semibold text-basicWhite py-4 md:py-0 ${width < 768 &&
                                                    index ===
                                                    expanded &&
                                                    'border-b border-primary-100 '
                                                    }`}
                                            >
                                                <Text
                                                    content={
                                                        menu?.title
                                                    }
                                                />
                                                {width < 768 && (
                                                    <ChevronDownIcon
                                                        className={`w-5 h-5 transform transition-all ease-linear duration-150  ${index ===
                                                            expanded
                                                            ? 'rotate-180'
                                                            : 'rotate-0'
                                                            }`}
                                                    />
                                                )}
                                            </motion.header>
                                            <AnimatePresence
                                                initial={false}
                                            >
                                                {(width >= 768 ||
                                                    index ===
                                                    expanded) && (
                                                        <motion.section
                                                            key="content"
                                                            initial="collapsed"
                                                            animate="open"
                                                            exit="collapsed"
                                                            variants={{
                                                                open: {
                                                                    opacity: 1,
                                                                    height: 'auto',
                                                                    x: 0,
                                                                },
                                                                collapsed: {
                                                                    opacity: 0,
                                                                    height: 0,
                                                                    x: '-100vw',
                                                                },
                                                            }}
                                                            transition={{
                                                                duration: 0.5,
                                                                ease: [
                                                                    0.04,
                                                                    0.62,
                                                                    0.23,
                                                                    0.98,
                                                                ],
                                                            }}
                                                        >
                                                            {menu?.subMenu?.map(
                                                                (
                                                                    items,
                                                                    index
                                                                ) => (
                                                                    <Link
                                                                        href={
                                                                            items?.link
                                                                        }
                                                                        key={`${items?.title}-${index}`}
                                                                        target={items?.target}
                                                                        onClick={() => triggerMenuGaEvents(items?.title)}
                                                                    >
                                                                        <motion.p
                                                                            variants={{
                                                                                collapsed:
                                                                                {
                                                                                    scale: 0.8,
                                                                                },
                                                                                open: {
                                                                                    scale: 1,
                                                                                },
                                                                            }}
                                                                            transition={{
                                                                                duration: 0.3,
                                                                            }}
                                                                            className="p5-regular text-primary-30 mt-2.5"
                                                                        >
                                                                            {
                                                                                items?.title
                                                                            }
                                                                        </motion.p>
                                                                    </Link>
                                                                )
                                                            )}
                                                        </motion.section>
                                                    )}
                                            </AnimatePresence>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>

                {router.pathname === '/' || routerPath === '/signup' ? (
                    <FooterText />
                ) : null}

                {router.pathname !== '/' && routerPath !== '/signup' ? (
                    <div className="w-full xl:h-[0.5px] bg-primary-30 my-4 xl:my-6" />
                ) : null}


                <div className='flex justify-center md:justify-between items-center'>
                    <Text
                        className='p6-regular text-primary-30 hidden xl:block shrink-0'
                        content={
                            replaceKeysWithValues(texts?.Copyright, {
                                '{copyRightYear}': copyRightYear,
                                '{brandName}': brandName,
                            })
                        }
                    />
                    <Text
                        className="p6-regular text-primary-30 hidden md:block"
                        content={texts?.PoweredBy}
                    />
                    {/* Links */}
                    <div className="p6-medium text-basicWhite w-max">
                        <FooterLinks />
                    </div>
                </div>

                <div className="w-full h-[0.5px] bg-primary-30/30 my-3 xl:hidden" />

                <div className="flex flex-col items-center xl:hidden p6-regular text-primary-30">
                    <Text
                        className="md:hidden text-center"
                        content={texts?.PoweredBy}
                    />
                    <Text
                        className="mt-2"
                        content={replaceKeysWithValues(texts?.Copyright, {
                            '{copyRightYear}': copyRightYear,
                            '{brandName}': brandName,
                        })}
                    />
                </div>
                    <div
                        className={`flex gap-2 flex-1 xl:flex-none block mt-4 justify-center items-center md:hidden`}
                    >
                        <Image
                            className="w-20 h-20"
                            src="/images/iso-certificate.webp"
                            alt={texts?.iso}
                        />
                    </div>
            </div>
        </div>
    );
};

export default LoggedOutFooter;