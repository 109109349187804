import Button from '@atoms/Button';
import Text from '@atoms/Text';
import Loader from '@components/Investor/Dashboard/Loader';
import React from 'react';

const PaginateButton = ({
    btnText = 'Show More',
    onClick,
    getShownRecordsCount,
    loading,
    pagesLeft,
    totalRecords,
    recordType,
}) => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            {!loading ? (
                <>
                    {
                        <div className="text-gray-500 pb-6 pt-5">
                            <Text content={'Showing'} htmlTag="span" />
                            <Text
                                className="font-semibold"
                                htmlTag="span"
                                content={` ${getShownRecordsCount()} of ${totalRecords} `}
                            />
                            <Text content={`${recordType}`} htmlTag="span" />
                        </div>
                    }
                    {pagesLeft > 0 && (
                        <Button
                            id={`paginate${btnText}Button`}
                            design="standard"
                            type="primary"
                            className="justify-center w-56 p-4 text-basicBlack bg-secondary-900"
                            icon="no"
                            onClick={onClick}
                            children={
                                <Text
                                    size="base"
                                    className="font-medium"
                                    content={btnText}
                                />
                            }
                        />
                    )}
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default PaginateButton;
