import { useLottie } from 'lottie-react';
import FestivalAnimationJson from '../public/assets/festival-animation.json';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { festiveGifPages } from '@helpers/constants';
import Image from '@atoms/Image';
import { useInvestorContext } from '@context/InvestorContextProvider';

const FestivalAnimation = ({ isLoggedIn }) => {
    const options = {
        animationData: FestivalAnimationJson,
        loop: true,
    };
    const { investorCache } = useInvestorContext();

    const { View } = useLottie(options);
    const router = useRouter();

    const isFestiveGifVisible = () => {
        const page = festiveGifPages.find(
            (route) => router.pathname === route.page
        );

        if (
            router.pathname === '/opportunities' &&
            investorCache?.kycStatus === 'Pending'
        ) {
            return 'md:block hidden';
        }

        return page?.display || 'hidden';
    };
    const displayClass = useMemo(
        () => isFestiveGifVisible(),
        [router?.pathname]
    );

    return (
        <div
            className={`${
                !isLoggedIn ? 'block' : displayClass
            } absolute right-2 xl:right-6 -z-10 top-[51px] md:top-[59px] xl:top-[79px]`}
        >
            <Image
                src="/images/festival-animation-top-line.svg"
                className="mx-auto"
            />
            <div className="w-[84px] md:w-[100px] xl:w-[136px] -mt-[20px]">{View}</div>
        </div>
    );
};

export default FestivalAnimation;
