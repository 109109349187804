import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useBackNavigation = (baseUrl = '/') => {
  const router = useRouter();

  useEffect(() => {
    // Check if the internal navigation flag is already set
    const internalNavigation = sessionStorage.getItem('internalNavigation');

    if (internalNavigation === null) {
      // If not set, set it to 'true' for internal navigations
      const handleRouteChange = () => {
        sessionStorage.setItem('internalNavigation', 'true');
      };

      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }
  }, [router.events]);

  const handleBack = (url, cb) => {
    const internalNavigation = sessionStorage.getItem('internalNavigation');
    if (internalNavigation === 'true') {
      router.back();
      cb() // regular behaviour
    } else {
      router.push(url || baseUrl);
    }
  };

  return handleBack;
};

export default useBackNavigation;