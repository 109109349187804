const Button = ({
    children,
    icon,
    onClick,
    isLoading,
    buttonType,
    paddingClass = 'p-4',
    wrapperClass = '',
    borderRadiusClass = 'rounded-lg',
    cursorClass = 'cursor-pointer',
    disabledClass = 'opacity-50',
    dataGeBtn = '',
    ...property
}) => {
    return (
        <div className={`${wrapperClass}`}>
            {(() => {
                if (
                    property.design == 'standard' &&
                    property.type == 'primary'
                ) {
                    return (
                        <button
                            type={buttonType}
                            data-ge-btn={dataGeBtn}
                            onClick={onClick}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            className={`relative ${paddingClass}   flex justify-center items-center ${property.textcolor
                                ? property.textcolor
                                : 'text-basicWhite'
                                }  ${borderRadiusClass} ${property.color
                                    ? property.color
                                    : 'bg-primary-500'
                                } text-${property.size}  ${property.className} ${property.state === 'disabled'
                                    ? disabledClass
                                    : ''
                                }
                            ${property.borderClass ? property.borderClass : ''}
                            ${isLoading ? 'cursor-not-allowed' : cursorClass}`}
                            id={property.id}
                            {...property.dataCaptureForGE}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 border-basicWhite rounded-full left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
                if (property.design == 'standard' && property.type == 'ghost') {
                    return (
                        <button
                            type={buttonType}
                            data-ge-btn={dataGeBtn}
                            onClick={onClick}
                            className={`relative ${paddingClass} flex border border-gray-600 ${borderRadiusClass} justify-center items-center text-${property.size
                                }  ${property.className} ${property.textcolor
                                    ? property.textcolor
                                    : 'text-primary-500'
                                }`}
                            id={property.id}
                            {...property.dataCaptureForGE}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 rounded-full border-primary-500 left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
            })()}
        </div>
    );
};
export default Button;
