import { Image } from '@atoms/index';
import { useState, useEffect } from 'react';
const FilterDropDown = ({
    label,
    contentImgPath,
    contentText,
    option,
    error,
    setValue,
    wrapperClassName,
    clearFilter,
    isClearFilter = false,
    ...property
}) => {
    const [currentValue, setCurrentValue] = useState();
    useEffect(() => {
        return setValue('');
    }, []);
    const handleChange = (event) => {
        let { id } = event.target;
        setValue(id);
    };
    return (
        <button
            className={wrapperClassName}
            onClick={() => {
                property.setSelectFilter(property.id);
            }}
            onBlur={() => {
                property.setSelectFilter('');
            }}
        >
            {label && <label className="mb-2">{label}</label>}

            <div
                className={`box-border border-1 cursor-pointer ${property.className}`}
                onChange={(e) => handleChange(e)}
            >
                {!currentValue && (
                    <>
                        <Image
                            src={contentImgPath}
                            className={`inline-block ${property.contentImgClass}`}
                        />
                        <span className="px-3">{contentText}</span>
                    </>
                )}
                {currentValue && (
                    <>
                        <span className="px-2">{currentValue}</span>
                        <Image
                            src="/images/cancel.svg"
                            className={`${property.contentImgClass}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setValue('');
                                setCurrentValue('');
                                isClearFilter && clearFilter();
                            }}
                        />
                    </>
                )}
            </div>
            {property.id === property.selectFilter && (
                <div
                    className={`absolute mt-2 bg-basicWhite ${property.optionClassName}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (e.target.id) {
                            setCurrentValue(e.target.innerText);
                            handleChange(e);
                            property.setSelectFilter('');
                        }
                    }}
                >
                    {option.map((item, index) => {
                        return (
                            <div
                                id={item.value}
                                key={index}
                                className="cursor-pointer m-1 p-1 text-center rounded-lg text-gray-500 hover:bg-primary-50 hover:text-primary-500 hover:font-normal"
                            >
                                {item.label}
                            </div>
                        );
                    })}
                </div>
            )}
            {error && (
                <div className="mt-2 text-left text-red-900 ">{error}</div>
            )}
        </button>
    );
};

export default FilterDropDown;
