import Text from "@atoms/Text";

export const OpportunityOverviewHeaderText = ({ title, text }) => {
    return (
        <div className="flex flex-col gap-2 px-2 w-auto">
            <Text
                content={title}
                className="text-gray-500 p6-medium"
                isInnerHtml={true}
            />
            <Text
                content={text}
                size="base"
                className="text-gray-900 p4-semibold"
            />
        </div>
    );
};
