import { motion } from 'framer-motion';
import Image from '@atoms/Image';
import { useEffect } from 'react';

const Modal = ({
    show,
    onClose,
    onCloseButtonMouseDown,
    label,
    width,
    height,
    hideTitle,
    hideCloseButton = false,
    children,
    labelSize,
    id,
    labelPadding,
    animation,
    mainWrapperClassName = '',
    paddingClass = 'p-6',
    labelBorderClass = 'border-b',
    labelParaClass = 'font-semibold font-heading text-gray-800',
    labelDivJustifyClass,
    roundedClass = 'rounded-lg',
    bgLabelClass = '',
    marginClass = 'm-2 md:mx-0',
    isError = false,
    position = '',
    ...property
}) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [show]);
    const animations = {
        popIn: {
            hidden: {
                opacity: 0,
            },
            visible: {
                opacity: [1, 1, 1, 1, 1],
                scale: [0, 0.25, 0.5, 0.75, 1, 1.01, 1],
                transition: {
                    duration: 0.3,
                    type: 'spring',
                    damping: 500,
                    stiffness: 700,
                },
            },
            exit: {
                opacity: 0,
                scale: [1, 0.5, 0],
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
        },
        dropIn: {
            hidden: {
                x: '-100vw',
                opacity: 0,
            },
            visible: {
                x: '0',
                opacity: 1,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
            exit: {
                x: '-100vw',
                opacity: 0,
                transition: {
                    duration: 0.3,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
        },
        dropInY: {
            hidden: {
                y: '-100vh',
                opacity: 0,
            },
            visible: {
                y: '0',
                opacity: 1,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
            exit: {
                y: '200vh',
                opacity: 0,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 1000,
                },
            },
        },
        newspaper: {
            hidden: {
                transform: 'scale(0) rotate(720deg)',
                opacity: 0,
                transition: {
                    delay: 0.3,
                },
            },
            visible: {
                transform: ' scale(1) rotate(0deg)',
                opacity: 1,
                transition: {
                    duration: 0.5,
                },
            },
            exit: {
                transform: 'scale(0) rotate(-720deg)',
                opacity: 0,
                transition: {
                    duration: 0.3,
                },
            },
        },
        flip: {
            hidden: {
                transform: 'scale(0) rotateX(-360deg)',
                opacity: 0,
                transition: {
                    delay: 0.3,
                },
            },
            visible: {
                transform: ' scale(1) rotateX(0deg)',
                opacity: 1,
                transition: {
                    duration: 0.5,
                },
            },
            exit: {
                transform: 'scale(0) rotateX(360deg)',
                opacity: 0,
                transition: {
                    duration: 0.5,
                },
            },
        },
        opacity: {
            hidden: {
                opacity: 0,
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: 0.1,
                },
            },
            hidden: {
                opacity: 0,
                transition: {
                    duration: 0.1,
                },
            },
        },
    };

    const yScroll = height ? `overflow-y-auto ${height}` : 'overflow-y-auto';
    const lableFontSize = labelSize ? labelSize : 'text-xl md:text-2xl';

    return (
        <div
            className={`fixed top-0 left-0  items-center justify-center w-full h-full modal ${property.customZIndex ? property.customZIndex : 'z-100'}  p-4 ${!show ? 'hidden' : 'flex !mt-0'
                } ${mainWrapperClassName}`}
        >
            <motion.div
                variants={animations.opacity}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="absolute z-40 w-full h-full bg-gray-900 !opacity-50 modal-overlay"
            ></motion.div>

            <motion.div
                variants={animation && animations[animation]}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={`text-basicBlack bg-basicWhite w-full ${marginClass} ${width} ${roundedClass} shadow-lg z-50 ${height} flex flex-col ${position}`}
            >
                {!hideTitle && (
                    <div
                        className={`flex items-center ${labelDivJustifyClass || 'justify-between'} ${bgLabelClass} ${labelBorderClass} ${labelPadding ? labelPadding : 'p-5'
                            }`}
                    >
                        <div className='flex items-center gap-x-2'>
                            {isError && <Image src='/images/Exclamation-circle-solid-red.svg' alt='error' className='w-[28px] h-[28px]' />}
                            <p className={`${labelParaClass}  ${lableFontSize}`}>
                                {label}
                            </p>
                        </div>
                        {!hideCloseButton &&
                            <div className="z-50 cursor-pointer modal-close flex items-center">
                                <button onClick={onClose} onMouseDown={onCloseButtonMouseDown} id={id} type='button'>
                                    <Image
                                        src="/images/close-without-border.svg"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                            </div>
                        }
                    </div>
                )}
                <div
                    className={`${paddingClass} ${property.className} ${yScroll}`}
                >
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default Modal;
