import React from 'react';

const Skeleton = ({
    className = 'w-140 h-24 rounded-md mx-auto',
    animationType = 'animate-pulse',
    render = (
        <>
            <div className="w-140 bg-gray-100 h-6 rounded-md "></div>
            <div className="w-120 bg-gray-100 h-6 rounded-md "></div>
        </>
    ),
    flexWidth = '',
}) => {
    return (
        <div className={className}>
            <div
                className={`flex ${animationType} flex-row items-center h-full justify-center space-x-5`}
            >
                <div className={`flex flex-col space-y-2 ${flexWidth}`}>{render}</div>
            </div>
        </div>
    );
};

export default Skeleton;
