import { Text, ToolTip } from '@atoms/index';

const Element = ({
    label,
    value,
    marginClass = 'mt-4',
    tooltipContent,
    valueClassName = 'p4-semibold whitespace-nowrap',
    toolTipPosition = 'absolute top-8 -left-28 ml-3 md:ml-0 lg:left-auto lg:right-0',
}) => {
    return (
        <div className={`flex justify-between ${marginClass}`}>
            <div className="flex items-center">
                <Text content={label} className="pr-1 p5-regular" />
                {tooltipContent && (
                    <ToolTip
                        content={tooltipContent}
                        paddingClass="p-4"
                        position={toolTipPosition}
                    />
                )}
            </div>
            <Text content={value} className={valueClassName} />
        </div>
    );
};

export default Element;
