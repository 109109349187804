import { FilterIcon, XIcon } from '@heroicons/react/outline';
import { Image, Text } from '@ui/atoms';
import { Button } from '@ui/atoms/design-system';
import { useEffect, useState, Fragment } from 'react';
import texts from '@components/Investor/Opportunities/en.json';
import { replaceKeysWithValues } from '@helpers/string';
const AppliedFilters = ({
    params = [],
    options = [],
    labelToShow = {
        replaceValuesWithLabel: {},
        replaceItems: [],
    },
    count = 0,
    openFilters = () => {},
    onRemove = () => {},
    onReset = () => {},
}) => {
    const [values, setValues] = useState([]);

    useEffect(() => {
        // Recreating an map of filters from given saved params
        const data = Object.keys(params)
            .filter((ob) => params[ob])
            .reduce((obj, key) => {
                return Object.assign(obj, {
                    [key]:
                        typeof params[key] === 'string'
                            ? params[key]?.split(',')
                            : [params[key]],
                });
            }, {});
        setValues(data);
    }, [params]);

    // render value
    const getValue = (name, val) => {
        return labelToShow?.replaceItems.includes(name)
            ? labelToShow?.replaceValuesWithLabel?.[val] || val
            : val;
    };

    return (
        <div className="pb-4 px-6 gap-3">
            <div>
                <Text
                    content={replaceKeysWithValues(texts?.DealsFoundCountText, {
                        '{count}': count || 0,
                    })}
                    size="xs"
                    className="h5-semibold text-primary-500 mb-4"
                />
            </div>
            <div className="flex flex-col-reverse items-start gap-6 xl:gap-4 xl:items-center xl:flex-row justify-between">
                <div className="flex gap-2 flex-wrap justify-start md:justify-start">
                    {options?.map((option, i) => {
                        return (
                            <Fragment key={i}>
                                {values?.[option?.name]?.map((val) => (
                                    <span
                                        className="py-2 px-4 bg-primary-30 text-primary-500 flex gap-2 p5-medium items-center rounded-md font-paragraph"
                                        key={val}
                                    >
                                        {getValue(option?.name, val)}
                                        <XIcon
                                            className="w-4 h-4 cursor-pointer border-none border-gray-900 rounded-sm p5-medium"
                                            onClick={() =>
                                                onRemove(option?.name, val)
                                            }
                                        />
                                    </span>
                                )) || null}
                            </Fragment>
                        );
                    })}
                </div>
                <div className="flex gap-2 xl:justify-center xl:items-center min-w-50 relative z-10">
                    <Button
                        buttonText={texts?.FilterBy}
                        prefixComponent={
                            <FilterIcon className="w-4 h-4 mr-1" />
                        }
                        buttonType="primary"
                        buttonSize="extraSmall"
                        onClick={openFilters}
                    />

                    {Object.values(values)?.length > 0 ? (
                        <Button
                            buttonText={texts?.ResetAll}
                            buttonType="outline"
                            buttonSize="extraSmall"
                            onClick={onReset}
                            prefixComponent={
                                <Image src="/images/reset.svg" alt="reset" className='mr-1' />
                            }
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default AppliedFilters;
