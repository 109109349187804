import { getAuth } from '@services/identity.service';
import { useEffect, useState } from 'react';
import LoggedInFooter from './LoggedInFooter';
import LoggedOutFooter from './LoggedOutFooter';

const FooterV2 = ({ className, entityType, ...property }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const auth = getAuth();

    useEffect(() => {
        if (auth) {
            setIsLoggedIn(true);
        } else setIsLoggedIn(false);
    }, [auth]);

    return isLoggedIn ? <LoggedInFooter /> : <LoggedOutFooter />;
};

export default FooterV2;
