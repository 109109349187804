const Select = ({
    label,
    option,
    selected,
    error,
    setValue,
    radiusClass = 'rounded-sm',
    borderClass = 'border-1 border-gray-300',
    ...property
}) => {
    const handleChange = (event) => {
        let { value } = event.target;
        setValue(value);
    };
    return (
        <>
            {label && <label className="mb-2">{label}</label>}
            <select
                value={selected}
                className={`box-border w-full bg-basicWhite p-3  appearance-none dropdown-icon ${radiusClass} ${borderClass} ${property.className}`}
                onChange={(e) => handleChange(e)}
            >
                {option}
            </select>
            {error && (
                <div className="mt-2 text-left text-red-900 ">{error}</div>
            )}
        </>
    );
};

export default Select;
