import Image from '@atoms/Image';

const HomeNavbar = (props) => {
    return (
        <div className={`sticky top-0 z-30 justify-between bg-basicWhite text-basicWhite ${props.isUserSurvey ? 'py-5' : 'py-5 md:py-7'}`}>
            <div className={`${props.isUserSurvey ? 'px-8' : 'container'} max-w-screen-maxScreen mx-auto `}>
                <div className="flex justify-center w-full">
                    <div className={`${props.onClick ? 'cursor-pointer' : ''}`} onClick={props.onClick}>
                        {
                            props.isUserSurvey ? <Image src="/images/logo.svg" alt="logo" className='w-28' /> :
                                <Image src="/images/logo.svg" alt="brand-logo" className='h-8' />
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HomeNavbar;
