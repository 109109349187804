import {UrlParamsReplace} from "@services/urls/index";

export const GET_PRE_SIGNED_URL = (bucket,key, expiredAt) =>
    UrlParamsReplace('/files/pre-signed-url?bucket=:bucket&key=:key&expiredAt=:expiredAt', {
        bucket,
        key,
        expiredAt
    });

/**
 * This API is deprecated. Please do not use this unless you consult with Vasu, Dilip or Ramakrishna
 /*
/*export const GET_DELETE_FILE_URL = (bucket,key) =>
    UrlParamsReplace('/files', {
        bucket,
        key
    });*/

export const UPLOAD_FILE_URL = () => UrlParamsReplace('/files/upload');

export const GET_RENAME_FILE_URL = () => UrlParamsReplace('/files/rename');
export const GET_FILE_URL = (bucket, key) => UrlParamsReplace('/files?bucket=:bucket&key=:key', {
    bucket, key
});
