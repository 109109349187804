import { useEffect } from "react";

const Input = ({
    register,
    id,
    type,
    placeholder,
    label,
    error,
    onClick,
    onPaste,
    borderWeight,
    borderColor = 'border-gray-300',
    inputpaddingClassName = 'px-4 py-3',
    inputMarginClassName = 'mt-2',
    errorClassName = '',
    labelClass = '',
    shouldUpdateNumberOnScroll = true, // default behavior of "number" type field
    ...inputProps
}) => {
    // removing the default behavior of incrementing/decrementing number field on scroll
    const removeScroll = (event) => {
        const preventScroll = (e) => e.preventDefault();

        event.target.addEventListener('wheel', preventScroll, { passive: false });
        return () => {
            event.target.removeEventListener('wheel', preventScroll); // Cleanup listener
        };
    };

    useEffect(() => {
        if (!shouldUpdateNumberOnScroll && type === 'number' && id) {
            document.getElementById(id)?.addEventListener('focus', removeScroll);
        }

        return () => {
            if (!shouldUpdateNumberOnScroll && type === 'number' && id)
                document.getElementById(id)?.removeEventListener('focus', removeScroll); // Cleanup listener
        };
    }, [id, type]);

    return (
        <>
            {label && (
                <label
                    className={`block w-full text-left text-neutral-900 ${labelClass}`}
                >
                    {label}
                </label>
            )}
            <input
                {...register(id)}
                id={id}
                {...inputProps}
                type={type}
                onClick={onClick}
                onPaste={onPaste}
                className={`${error ? 'border-semantic-error-base' : borderColor} ${borderWeight ? borderWeight : 'border-2'
                    } ${inputProps.width ? inputProps.width : 'w-full'} ${inputProps.height ? inputProps.height : 'h-12'
                    } ${inputpaddingClassName} rounded-lg ${inputMarginClassName} box-border ${inputProps.className
                    }`}
                placeholder={placeholder}
            />
            {error && (
                <div className={`text-left text-semantic-error-base ${errorClassName}`}>
                    {error.message}
                </div>
            )}
        </>
    );
};

export default Input;
