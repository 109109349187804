import React, { useState } from 'react';

const Tab = ({ children, isAdminUi, defaultTab = 'tab1' }) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const switchTab = (tabref, onTabSelect) => {
        setActiveTab(tabref);
        if (onTabSelect) {
            onTabSelect();
        }
    };
    const adminActiveTabClass =
        'focus:outline-none border-b-2 text-basicBlack bg-yellow-500 md:border-yellow-500 text-base font-medium';
    const adminInActiveTabClass =
        'hover:text-yellow-500 text-base border-b-2 borger-gray-200 text-basicBlack font-normal';
    var TabNav = ({ label, tabref, onTabSelect, id }) => {
        return (
            <button
                onClick={() => switchTab(tabref, onTabSelect)}
                className={`py-4 px-4 block ${activeTab == tabref
                    ? `${isAdminUi
                        ? adminActiveTabClass
                        : 'focus:outline-none border-b-2 text-primary-700 md:border-primary-700 text-base font-medium'
                    }`
                    : `${isAdminUi
                        ? adminInActiveTabClass
                        : 'hover:text-primary-700 text-base  text-gray-500 font-normal'
                    }`
                    }`}
                id={id}
            >
                {' '}
                {label}
            </button>
        );
    };

    const TabNBody = ({ content, tabref }) =>
        activeTab === tabref ? <div>{content}</div> : null;

    return (
        <div className="">
            <nav className="z-10 flex flex-wrap mr-1 bg-basicWhite shadow-md top-14 lg:top-20">
                {children.map((element, i) => {
                    return (
                        <TabNav
                            key={`tabnavkey-${i}`}
                            label={element.props.label}
                            tabref={element.props.tabref}
                            onTabSelect={element.props.onTabSelect}
                            id={element.props.id}
                        />
                    );
                })}
            </nav>
            <div className="">
                {children.map((element, i) => {
                    return (
                        <TabNBody
                            key={`tabbodykey-${i}`}
                            content={element.props.children}
                            tabref={element.props.tabref}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Tab;
