import HeaderMenuItem from '@molecules/menu/HeaderMenuItem';
import Skeleton from '@atoms/Skeleton';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

const HeaderMenuItems = ({
    menusLoading,
    menu,
    isLoggedIn,
    homePage,
    openMenuIndex,
    setOpenMenuIndex,
}) => {
    const ref = useRef();
    const routePath = useRouter().pathname;

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenMenuIndex(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="flex items-center h-full" ref={ref}>
            <ul className="flex items-center h-full">
                {!menusLoading ? (
                    menu
                        ?.filter((each) => each !== null)
                        ?.filter((each) => {
                            if (
                                isLoggedIn &&
                                each?.showWhenLoggedIn &&
                                (homePage || !each?.showOnHomePageOnly)
                            ) {
                                return each;
                            } else if (
                                !isLoggedIn &&
                                each?.showWhenNotLoggedIn
                            ) {
                                return each;
                            }
                        })
                        .map((menuItem, index) => {
                            return (
                                <li
                                    className={`px-2 h-full flex items-center ${
                                        // menuItems.submenu &&
                                        menuItem.selectOn &&
                                        menuItem.selectOn.includes(routePath) &&
                                        'border-b-[3px] border-primary-500'
                                    }`}
                                    key={index}
                                >
                                    <HeaderMenuItem
                                        menuItem={menuItem}
                                        selected={index === openMenuIndex}
                                        isLoggedIn={isLoggedIn}
                                        setSelected={(val) =>
                                            setOpenMenuIndex(val ? index : null)
                                        }
                                    />
                                </li>
                            );
                        })
                ) : (
                    <Skeleton />
                )}
            </ul>
        </div>
    );
};

export default HeaderMenuItems;
