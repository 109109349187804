import { useMemo } from "react";
import WithAuthHeader from "./WithAuthHeader";
import WithoutAuthHeader from "./WithoutAuthHeader";
import { useRouter } from "next/router";
import texts from '@molecules/NavHeaderMobile/en.json';

const NavHeaderMobile = ({
    name,
    menuOpen,
    handleMenu,
    height,
    rmDetails,
    menu,
    isLoggedIn,
    LoginButton,
    menuClicked,
    setMenuOpen,
    isFestiveSeason,
    isHeaderVisible,
    isStaticPage,
    logout,
    kycStatus,
    isAppRedirection,
    appDashboardLink
}) => {
  const router = useRouter();
  // not show side nav bar for these pages : texts.notShowSideNavigationIcon
  const notShowSideNavigation = useMemo(() => texts.notShowSideNavigationIcon.includes(router.asPath), [router.asPath]);

  return isLoggedIn ? (
      <WithAuthHeader
          {...{
              name,
              menuOpen,
              handleMenu,
              height,
              rmDetails,
              menu,
              isStaticPage,
              logout,
              kycStatus,
              isAppRedirection,
              appDashboardLink,
              notShowSideNavigation
          }}
      />
  ) : (
      <WithoutAuthHeader
          {...{
              menu,
              height,
              menuOpen,
              LoginButton,
              menuClicked,
              handleMenu,
              setMenuOpen,
              isFestiveSeason,
              isHeaderVisible,
          }}
      />
  );
}

export default NavHeaderMobile;
