import { Modal, Text } from '@atoms/index';
import { Button } from '@ui/atoms/design-system';

const MessageModal = ({ title, message, buttonText, onClick, isError, show, onClose }) => <Modal
    show={show}
    onClose={onClose}
    width="max-w-[440px]"
    label={title}
    labelBorderClass="border-none"
    labelSize=" "
    labelParaClass="h5-semibold text-primary-500"
    labelPadding="pb-4"
    roundedClass="rounded-xl"
    marginClass="p-4 md:p-6"
    paddingClass=''
    isError={isError}
    children={
        <div>
            <Text content={message} className="mb-4 text-gray-900 p5-regular" />
            <Button buttonType='primary' buttonSize='regular' buttonText={buttonText} onClick={onClick} widthClass='w-full' />
        </div>
    }
/>;

export default MessageModal;