const TextArea = ({
    register,
    error,
    label,
    placeholder,
    onClick,
    onPaste,
    id,
    borderWeight,
    wrapperClass = 'mb-3',
    ...inputProps
}) => {
    return (
        <div className={`relative w-full ${wrapperClass}`}>
            {label && (
                <label
                    className={`block w-full text-left text-gray-900 ${inputProps.labelClass}`}
                >
                    {label}
                </label>
            )}
            <textarea
                {...register(id)}
                id={id}
                {...inputProps}
                placeholder={placeholder}
                onClick={onClick}
                onPaste={onPaste}
                className={`${error ? 'border-semantic-error-base' : 'border-gray-300'} ${borderWeight ? borderWeight : 'border-2'
                    } ${inputProps.width ? inputProps.width : 'w-full'} ${inputProps.height ? inputProps.height : 'h-32'
                    } px-4 py-3 rounded-lg mt-2 box-border ${inputProps.className}`}
            />
            {error && (
                <div className="text-left text-semantic-error-base">{error.message}</div>
            )}
        </div>
    );
};

export default TextArea;
