import { Text, Image } from '@atoms/index';
import { allowedFileUploads, defaultUploadedPdfIcon } from '@helpers/constants';
import { useEffect, useState } from 'react';

const UploadedImageCard = ({
    label,
    imageSrc,
    isSingleUpload,
    uploadFile,
    setSuccessMessage,
    successMessage,
}) => {
    const uploadedImage = defaultUploadedPdfIcon;
    useEffect(() => {
        setTimeout(() => {
            setSuccessMessage(null);
        }, 2000);
    }, [successMessage]);
    return successMessage ? (
        <div className="flex flex-col gap-1 justify-center items-center w-full h-full">
            <Image
                src={'/images/sucessfullGreenTick.png'}
                className="w-8 h-8"
            />
            <Text
                content={successMessage}
                className="p6-medium text-basicBlack"
            />
        </div>
    ) : (
        <div
            className={`flex gap-4 justify-center h-full md:gap-4 ${
                isSingleUpload
                    ? 'flex-col items-center'
                    : 'md:flex-row flex-col items-center'
            } w-full rounded-lg`}
        >
            <div
                className={`flex flex-col w-full px-2 py-0 ${
                    isSingleUpload ? 'items-center' : ''
                }`}
            >
                <div className="flex item-center justify-center gap-1">
                    <Image src={uploadedImage} className="w-5 h-5" />
                    <Text
                        content={label}
                        className="text-primary-500 mb-2 p5-underline"
                    />
                </div>
                <label
                    htmlFor={'fileUpload' + imageSrc}
                    className="cursor-pointer"
                >
                    <div className="flex items-center mx-auto justify-center">
                        <Image src="/images/investors/kyc/re-upload-icon.svg" />
                        <Text
                            className="ml-1 text-secondary-600 p5-underline"
                            content="Reupload"
                        />
                    </div>
                </label>
                <input
                    type="file"
                    accept={allowedFileUploads}
                    id={'fileUpload' + imageSrc}
                    name="cameraInput"
                    onChange={(event) => uploadFile(event)}
                    className="hidden btn btn-default spacer-top"
                />
            </div>
        </div>
    );
};

export default UploadedImageCard;
