import { Image, Link, Text } from '@atoms/index';
import Submenu from './Submenu';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';

const HeaderMenuItem = ({ menuItem, selected, setSelected, isLoggedIn }) => {
    const routePath = useRouter().pathname;
    return (
        <>
            {menuItem?.submenu?.filter((each) =>
                isLoggedIn ? each.showWhenLoggedIn : !each.showWhenLoggedIn
            ) ? (
                <div
                    className="relative"
                    onClick={() => setSelected(!selected)}
                >
                    <div
                        className={` ${
                            menuItem.selectOn &&
                            menuItem?.selectOn.includes(routePath)
                                ? 'text-primary-500 p4-semibold -mb-1'
                                : 'text-gray-500 hover:text-primary-500 p4-medium'
                        } cursor-pointer flex flex-grow items-center justify-center`}
                    >
                        <Text
                            content={menuItem.name}
                            className="text-base max-w-min xl:max-w-max whitespace-nowrap font-heading"
                            id={menuItem.id}
                        />
                        {menuItem.submenu && (
                            <ChevronDownIcon
                                className={`h-4 w-5 transform-all duration-100 ${
                                    selected ? 'rotate-180' : ''
                                }`}
                            />
                        )}
                    </div>
                    <div
                        className={`duration-100 translate-all ${
                            selected
                                ? 'translate-y-0'
                                : 'invisible translate-y-2'
                        }`}
                    >
                        <Submenu
                            menuItems={menuItem?.submenu?.filter((each) =>
                                isLoggedIn ? each.showWhenLoggedIn : each
                            )}
                        />
                    </div>
                </div>
            ) : (
                <Link href={menuItem.link} target={menuItem.target}>
                    <div
                        data-ge-menuItem={menuItem.name}
                        onClick={() => setSelected(!selected)}
                        id={menuItem.id}
                        className={`py-1 ${
                            menuItem.selectOn &&
                            menuItem?.selectOn.includes(routePath)
                                ? 'text-primary-500 p4-semibold -mb-1'
                                : 'text-gray-500 hover:text-primary-500 p4-medium'
                        } cursor-pointer whitespace-nowrap flex items-center font-heading`}
                    >
                        {menuItem?.isNew ? (
                            <div
                                data-ge-menuItem={menuItem.name}
                                className="bg-secondary-20 p-2 flex items-center relative rounded-md"
                            >
                                <Image
                                    src="/images/newDashboard/wallet-icon-light.svg"
                                    className="w-5 h-5 mr-2"
                                />
                                {menuItem.name}
                            </div>
                        ) : (
                            menuItem.name
                        )}
                    </div>
                </Link>
            )}
        </>
    );
};

export default HeaderMenuItem;
