import React from 'react';
import { Image, Text } from '@ui/atoms';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { UserIcon } from '@heroicons/react/solid';

const AvatarSection = ({ avatar, name, kycStatus }) => {
    const router = useRouter();

    const getShowKycStatus = (status) => {
        const showKycStatus = {
            Pending: { show: 'pending', icon: '/images/header/pending-white-icon.svg' },
            Verified: { show: 'verified', icon: '/images/header/success-white-icon.svg' },
            ManualVerificationRequired: { show: 'in verification', icon: '/images/header/pending-white-icon.svg' },
        };

        return showKycStatus[status];
    };

    const showKycStatus = getShowKycStatus(kycStatus);

    return (
        <div className="bg-secondary-10 p-3 flex items-center justify-between rounded-xl border-2 border-secondary-20 cursor-pointer" onClick={() => router.push('/profile')}>
            <div className="flex justify-center items-center gap-2">
                <div className="w-14 h-14 rounded-full bg-secondary-50 flex justify-center items-center">
                    {avatar || <UserIcon className='w-6 h-6 text-primary-500'/>}
                </div>
                <div className="flex flex-col gap-2">
                    <Text
                        content={name}
                        className="p4-medium text-primary-500"
                    />
                    <div className={`bg-primary-400 text-basicWhite px-2 py-1 rounded-2xl self-start flex justify-center items-center p6-medium gap-1`}>
                        <Image src={showKycStatus?.icon} alt='status' className='w-4 h-4'/>
                        KYC {showKycStatus?.show}
                    </div>
                </div>
            </div>
            <button>
                <ChevronRightIcon className="w-6 h-6 text-primary-500" />
            </button>
        </div>
    );
};

export default AvatarSection;
