import moment from "moment";
import FooterLinks from "./FooterLinks";
import AppLinks from "./AppLinks";
import { replaceKeysWithValues } from "@helpers/string";
import texts from '@molecules/Footer/V2/en.json';
import Text from "@atoms/Text";
import { brandName } from "@helpers/constants";

const LoggedInFooter = () => {
    const copyRightYear = moment().format('YYYY');

    return (
        <div className='bg-primary-600 z-40' id="app-download">
            <div
                className='max-w-screen-maxScreen mx-auto px-6 py-3'
            >
                {/* Links */}
                <div className='p6-medium text-basicWhite w-full  flex xl:flex flex-col-reverse md:flex-row gap-x-6 gap-y-3 items-center justify-between'>
                    <FooterLinks className="shrink-0 hidden xl:flex" />
                    <AppLinks isLoggedIn={true} className="w-full justify-between xl:justify-end"/>
                </div>

                <div className="w-full h-[0.5px] bg-primary-30/30 my-3 hidden xl:block" />

                <div className='flex justify-center md:justify-between mt-3 items-center'>
                    <Text
                        className='p6-regular text-primary-30 hidden xl:block shrink-0'
                        content={
                            replaceKeysWithValues(texts?.Copyright, {
                                '{copyRightYear}': copyRightYear,
                                '{brandName}': brandName,
                            })
                        }
                    />
                    <Text
                        className="p6-regular text-primary-30 hidden md:block"
                        content={texts?.PoweredBy}
                    />
                    {/* Links */}
                    <div className='p6-medium text-basicWhite w-max xl:hidden'>
                        <FooterLinks />
                    </div>
                </div>

                <div className="w-full h-[0.5px] bg-primary-30/30 my-3 xl:hidden" />

                <div className="flex flex-col items-center xl:hidden p6-regular text-primary-30">
                    <Text
                        className="md:hidden text-center"
                        content={texts?.PoweredBy}
                    />
                    <Text
                        className='mt-2'
                        content={replaceKeysWithValues(texts?.Copyright, {
                            '{copyRightYear}': copyRightYear,
                            '{brandName}': brandName,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default LoggedInFooter;