import { Link, Text } from "@atoms/index";
import { footersLinks } from "./helper";

const FooterLinks = ({ className }) => {
    return (
        <div className={`flex gap-x-6 items-center p6-medium text-basicWhite ${className}`}>
            {footersLinks.map((footersLink, index) => (
                <Link href={footersLink.link} key={index} target='_blank'>
                    <Text content={footersLink.name} />
                </Link>
            ))}
        </div>
    );
}

export default FooterLinks;