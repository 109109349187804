const Avatar = ({ icon, cursor = 'cursor-pointer', ...property }) => {
    return (
        <div
            className={`w-8 h-8 inline-block text-center leading-8 ${property.className}`}
        >
            <div className={`flex items-center justify-center w-full h-full overflow-hidden text-center rounded-full ${cursor} shadow-inner group bg-primary-30 font-heading`}>
                <span className="font-medium leading-10 align-middle text-primary-500 group-hover:table-cell">
                    {icon}
                </span>
            </div>
        </div>
    );
};

export default Avatar;
