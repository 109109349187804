import Text from '@atoms/Text';
import texts from '@components/Investor/Kyc/en.json';

const KycLoader = ({ showText = true, loaderDimentions = 'w-6 h-6', loaderChildDimentions = 'w-3 h-[14px]' }) => {
    return (
        <div className="flex items-center gap-2 mt-3">
            <div className={`${loaderDimentions} border-[2.5px] rounded-full border-primary-50 flex items-center justify-center`}>
                <div className={`border-y-[2.3px] ${loaderChildDimentions} rounded-full border-primary-500 animate-spin`} />
            </div>
            {showText ? (
                <Text
                    content={texts?.FetchingDetails}
                    className="text-primary-500 p6-medium"
                />
            ) : null}
        </div>
    );
};

export default KycLoader;
