import { useEffect, useState } from 'react';

const Paginator = ({
    onClick,
    currentPage,
    totalRecords,
    pageSize,
    paginatorSize,
}) => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const [paginatorButtonTexts, setPaginatorButtonTexts] = useState([]);
    if (paginatorSize > totalPages) {
        paginatorSize = totalPages;
    }
    const goToPage = (pageNumber) => {
        if (
            pageNumber >= 1 &&
            pageNumber <= totalPages &&
            pageNumber != currentPage
        )
            window.scrollTo(0, 0);
        onClick(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const currentPageIsHigherThanPaginatorSize = () =>
        currentPage > paginatorSize;
    const paginatorButtonTextsIncludeCurrentPage = () =>
        paginatorButtonTexts.find(
            (paginatorButtonText) => paginatorButtonText == currentPage
        );

    useEffect(() => {
        if (
            currentPageIsHigherThanPaginatorSize() &&
            !paginatorButtonTextsIncludeCurrentPage()
        ) {
            setPaginatorButtonTexts(
                [...Array(paginatorSize)].map(
                    (index, i) => currentPage + 1 - (paginatorSize - i)
                )
            );
        } else if (
            !currentPageIsHigherThanPaginatorSize() &&
            !paginatorButtonTextsIncludeCurrentPage()
        ) {
            setPaginatorButtonTexts(
                [...Array(paginatorSize)].map((index, i) => i + 1)
            );
        }
    }, [currentPage]);

    useEffect(() => {
        setPaginatorButtonTexts(
            [...Array(paginatorSize)].map((index, i) => i + 1)
        );
        onClick(1);
    }, [totalPages]);

    return (
        <div className="flex items-center justify-center mb-4">
            <button
                className="px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 rounded-l outline-none text-gray-600 hover:bg-gray-300 hover:text-gray-600 active:bg-gray-300 focus:outline-none"
                type="button"
                onClick={() => goToPage(1)}
            >
                <i className="fas fa-angle-double-left">{`<<`}</i>
            </button>
            <button
                className="px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 outline-none text-gray-600 hover:bg-gray-300 hover:text-gray-600 active:bg-gray-300 focus:outline-none"
                type="button"
                onClick={() => goToPage(currentPage - 1)}
            >
                <i className="fas fa-angle-left">{`<`}</i>
            </button>
            {paginatorButtonTexts.map((value, i) => (
                <button
                    key={i}
                    className={`${currentPage == value
                        ? 'bg-gray-300 cursor-text'
                        : 'bg-gray-100'
                        } text-gray-600 hover:bg-gray-300 hover:text-gray-600 active:bg-gray-300 font-semibold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                    type="button"
                    onClick={() => goToPage(value)}
                >
                    {value}
                </button>
            ))}
            <button
                className="px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 outline-none text-gray-600 hover:bg-gray-300 hover:text-gray-600 active:bg-gray-300 focus:outline-none"
                type="button"
                onClick={() => goToPage(currentPage + 1)}
            >
                <i className="fas fa-angle-right">{`>`}</i>
            </button>
            <button
                className="px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 rounded-r outline-none text-gray-600 hover:bg-gray-300 hover:text-gray-600 active:bg-gray-300 focus:outline-none"
                type="button"
                onClick={() => goToPage(totalPages)}
            >
                <i className="fas fa-angle-double-right">{`>>`}</i>
            </button>
        </div>
    );
};

export default Paginator;
