const Checkbox = ({
    register,
    id,
    type = 'checkbox',
    label,
    error,
    onClick,
    labelClass,
    errorClassName,
    roundedBorder = 'rounded-lg',
    wrapperPaddingClass = 'py-4',
    marginClass = 'mx-4',
    labelTextColor = 'text-gray-500',
    ...inputProps
}) => {
    return (
        <>
            {label && (
                <div
                    className={`flex ${inputProps.flexJustify
                            ? inputProps.flexJustify
                            : 'justify-start'
                        } text-left text-gray-900 ${wrapperPaddingClass} ${inputProps.wrapperClass
                        }`}
                >
                    <input
                        {...register(id)}
                        id={id}
                        {...inputProps}
                        type={type}
                        onClick={onClick}
                        className={`${error ? 'border-semantic-error-base' : 'border-gray-300'
                            } ${inputProps.border ? inputProps.border : 'border-2'
                            } ${inputProps.width ? inputProps.width : 'w-full'} ${inputProps.height ? inputProps.height : 'h-12'
                            }  ${marginClass} ${roundedBorder} box-border self-center ${inputProps.className
                            } focus:text-primary-500 focus:ring-0 checked:text-primary-500`}
                    />
                    <label className={`${labelTextColor} ${labelClass}`}>
                        {' '}
                        {label}
                    </label>
                </div>
            )}

            {error && (
                <div className={`text-left text-semantic-error-base ${errorClassName}`}>{error.message}</div>
            )}
        </>
    );
};

export default Checkbox;
